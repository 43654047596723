::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: rgba(241, 241, 241, 1);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.navbar {
	-webkit-box-shadow: 2px 20px 80px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 2px 20px 80px rgba(0, 0, 0, 0.1);
	box-shadow: 2px 20px 80px rgba(0, 0, 0, 0.1);
	/* background:  linear-gradient(258.77deg, #2F387F -2.12%, #1C74B3 97.09%); */
	border: none;
	/* border-bottom: 1.5px solid lightgrey; */
	border-radius: 0px;
	margin: 0px;
	/* padding: 0px; */
	min-height: 75.43px;
	background-color: #ffffff;

	position: sticky;
	top: 0;
	z-index: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.navbar #img1 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.navbar li a {
	color: black;
	margin: 10px 20px 0px 20px;
}

.navbar #myNavbar #icon1 {
	font-size: 20px;
	margin: 22px 15px 10px 20px;
}

.navbar #myNavbar a {
	background: none;
}

.navbar #btn1 {
	/* background: linear-gradient(246.8deg, #C4C4C4 -5.42%, #293E80 -5.41%, #09AEE5 96.08%); */
	border: none;
	margin-top: 10px;
	padding: 10px;
	border-radius: 5px;
	font-weight: bold;
}

.navbar #btn2 {
	border: none;
	background: lightgrey;
	margin-top: 10px;
	padding: 10px;
	border-radius: 5px;
	width: 100px;
	font-weight: bold;
}

.navbar span {
	background: #868484;
}

.navbar-toggle span {
	background: white;
}

.navbar li a {
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 116.2%;
	text-transform: capitalize;
	color: #ffffff;
	outline: none;
}

.navbar li a:hover {
	text-decoration: none;
}

.box1 {
	/* padding: 10px 12px 0px 20px; */
	border-radius: 10px;
	box-shadow: 1px 2px 5px lightgrey;
	margin-bottom: 13px;
	border: 1px solid lightgrey;
	/* border-radius: 5px; */
	padding: 10px;
	min-height: 250px;
	/* background:linear-gradient(90.18deg, #9EC8FF 2.43%, #00AEEF 99.83%); */
	margin: 20px;
	text-align: center;
}

.box1:hover {
	background: linear-gradient(246.8deg, #c4c4c4 -5.42%, #293e80 -5.41%, #09aee5 30.08%);
	/* cursor: pointer; */
	/* opacity: 0.7; */
}

#btn3 {
	background: linear-gradient(246.8deg, #c4c4c4 -5.42%, #293e80 -5.41%, #09aee5 96.08%);
	border-radius: 48px;
	border: none;
	padding: 5px 10px 5px 10px;
	color: white;
	outline: none;
	/* width:110px; */
}

.box1:hover #btn3 {
	background: #ffffff;
	border-radius: 48px;
	/* font-family: Montserrat; */
	font-style: normal;
	font-weight: 600;
	/* font-size: 15px; */
	color: #0061b5;
}

.navigationHeader {
	width: 100vw;
	height: 80px;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #000000;
	display: flex;
	align-items: center;
	background: #ffffff;
	padding: 24px;
	padding-left: 18px;
	font-family: "Montserrat";
	font-style: normal;
	box-shadow: 2px 20px 80px rgba(0, 0, 0, 0.05);
}

@media (max-width: 1200px) {
	.navbar li a {
		margin: 10px 4px 0px 4px;
	}
}

@media (max-width: 992px) {
	.navbar #img1 {
		/* margin-top: 0px; */
		margin-left: 10px;
		height: 40px;
		width: 150px;
	}
}

#feeportal #bigcard {
	display: block;
}

#feeportal #smallcard {
	display: none;
}

@media (max-width: 991px) {
	#feeportal #bigcard {
		display: none;
	}

	#feeportal #smallcard {
		display: block;
	}
}

.navbar .dropbtn {
	/* margin: 15px;
    background: #ed7a27;
    padding: 7px;
    font-size: 20px;
    border-radius: 50%;
    border: none;
    width: 43px; */

	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 6px;

	width: 171px;
	height: 50px;

	background: #d6e8ff;
	border-radius: 100px;

	cursor: pointer;
}

.navbar .dropdown {
	position: relative;
	display: inline-block;
}

.navbar .dropdownContent {
	display: none;
	position: absolute;
	right: 0;
	background-color: #ffffff;
	min-width: 250px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius: 8px;
}

.navbar .dropdownContent a {
	display: flex;
	margin: 1.7rem;
	padding: 12px 16px;
	align-items: center;
	cursor: pointer;
	background-color: #f5fcff;
	border-radius: 8px;
}

.navbar .dropdownContent a:hover {
	background-color: #e5f7ff;
}

.navbar .dropdownItems:hover {
	background-color: #e5f7ff;
}

.navbar .dropdown:hover .dropdownContent {
	display: block;
}

.navbar #collapsed {
	display: none;
}

.navbar #collapsed li {
	padding: 20px;
}

.navbar #collapsed li:hover {
	background-color: #f1f1f1;
	text-decoration: none;
}

.navbar #collapsed li a {
	cursor: pointer;
	color: black;
	font-weight: bold;
}

.navbar #collapsed li a:hover {
	text-decoration: none;
}

.loaderContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-45px, -30px);
}

@media (max-width: 991px) {
	.navbar .dropdown {
		display: none;
	}

	.navbar #collapsed {
		display: block;
	}
}

.column {
	float: left;
	width: 33.33%;
	padding: 10px;
	height: 300px;
}

@media (max-width: 992px) {
	.column {
		/* float: none; */
		width: 100%;
		padding: 10px;
		height: 200px;
	}
}

.column1 {
	float: left;
	width: 33.33%;
	padding: 10px;
	height: 300px;
	margin-top: 30px;
}

@media (max-width: 992px) {
	.column1 {
		width: 100%;
		padding: 10px;
		height: 400px;
	}
}

.Profile_box {
	width: 200px;
	height: 400px;
	background-color: red;
}

@media only screen and (max-width: 991px) {
	.nav_heading img {
		max-width: 150px;
		width: 60%;
	}

	.navbar {
		min-height: 70.5px;
	}
}

.navbar .dropdownItems {
	display: flex;
	margin: 1.7rem;
	padding: 12px 16px;
	align-items: center;
	cursor: pointer;
	background-color: #f5fcff;
	border-radius: 8px;
}

.navbar .dropdownItemsText {
	margin-bottom: 0;
	margin-left: 1.5rem;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 132.6%;

	color: #000000;
}

.navbar .navbar_item {
	border: none;
	background: none;
	margin-right: 20px;
	text-decoration: none;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #070707;
}

@media screen and (max-width: 650px) {
	.navbar {
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		background-color: #f4f4f4;
	}
}
