* {
	scrollbar-width: thin;
}

.table-data {
	justify-content: center;
	align-items: center;
	margin: 5px 5px;
	padding-block: 7px;
	padding-inline: 3px;
}

.table-row {
	padding-block: "10px";
}

.nav-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 3px 4px rgba(170, 170, 170, 0.25);
	padding: 8px 16px;
	position: sticky;
	top: 0;
	z-index: 99;
	background-color: #ffffff;
}

.nav-logo-img {
	height: 50px;
}

.nav-bar-right {
	display: flex;
	align-items: center;
}

.timer-area {
	color: #ff0a0a;
	font-weight: 700;
	font-size: 1.3em;
	margin-right: 1.25rem;
}

.submit-btn,
.submit-btn:hover,
.submit-btn:focus {
	padding: 8px 25px;
	font-weight: 700;
	color: #012c63;
	background-color: #ffffff;
	border: 1px solid #012c63;
	border-radius: 0;
	letter-spacing: 0.015em;
}

.info a,
.info a:hover,
.info a:focus {
	color: #012c63;
	font-size: 1.5em;
	cursor: pointer;
}

.blue-text {
	color: #012c63;
}

.test-name {
	font-size: 1.3em;
	letter-spacing: 0.015em;
}

/* .tags{
    
} */

div.scroll {
	overflow-x: auto;
	white-space: nowrap;
	height: 3em;
	scrollbar-width: thin;
}

div.scroll-2 {
	overflow-x: auto;
	white-space: nowrap;
	height: 5em;
	padding: 10px 0;
	scrollbar-width: thin;
}

div.scroll-3 {
	overflow-x: auto;
	white-space: nowrap;
	/* height: 5em; */
	display: flex;
	padding: 10px;
}

.marking-scheme {
	font-size: 1.1em;
	color: #e97a26;
	cursor: pointer;
}

.marking-scheme:hover,
.marking-scheme:focus {
	text-decoration: underline;
	color: #e97a26;
}

.section {
	background: rgba(255, 255, 255, 0.15);
	box-shadow: 0.5px 0.5px 4px 2px #00000026;
	margin-right: 10px;
	margin-left: 5px;
	font-weight: bold;
	font-size: 1.1em;
	letter-spacing: 0.015em;
	color: #012c63;
	padding: 5px 25px;
}

.section:hover {
	cursor: pointer;
}

.tag {
	background: rgba(255, 255, 255, 0.15);
	font-size: 0.8em;
	border: 0.7px solid #cdcdcd;
	border-radius: 2px;
	padding: 0px 6px;
	color: #e97a26;
	letter-spacing: 0.015em;
	font-weight: bold;
	/* margin: 10px 4px; */
}

.exam .row {
	margin-left: 0;
}

.exam-questions {
	margin: 3% 1%;
	background: #ffffff;
	/* border: 0.8px solid #909090; */
	/* height: 50vh; */
	/* overflow: auto; */
	/* border-radius: 10px; */
}

.question-text {
	font-weight: 600;
	letter-spacing: 0.015em;
	color: #616161;
	overflow-x: auto;
}

.question-tag-wrapper {
	display: inline-flex;
	flex-wrap: wrap;
	column-gap: 10px;
	row-gap: 4px;
}

.question-tag {
	color: #e97a26;
	font-weight: 600;
	text-transform: uppercase;
	background: rgba(255, 255, 255, 0.15);
	border: 0.5px solid #cdcdcd;
	border-radius: 5px;
	padding: 2px 7px;
	font-size: 0.8em;
}

.boxed label {
	display: flex;
	padding: 3% 5%;
	border: solid 1px #ccc;
	/* transition: all 0.2s; */
	border-radius: 0.5em;
	margin: 10px auto;
	font-weight: 500;
}

.boxed2 label {
	display: flex;
	padding: 3% 5%;
	/* border: solid 1px #ccc; */
	/* transition: all 0.2s; */
	border-radius: 0.5em;
	margin: 10px auto;
	font-weight: 500;
	border: solid 1px #012c63;
	background-color: #012c63;
	color: #ffffff !important;
}

.boxed2 p,
.boxed2 span {
	/* Styling overwrite for highlighted option */
	color: #fff !important;
}

.boxed input[type="radio"] {
	display: none;
}

.boxed input[type="checkbox"] {
	display: flex;
}

.boxed input[type="radio"]:checked + label {
	border: solid 1px #012c63;
	background-color: #012c63;
	color: #ffffff;
}

/* .boxed label input[type="checkbox"]:checked {
    border: solid 1px #012C63;
    background-color: #012C63;
    color: #ffffff;
} */

/* .boxed input[type="checkbox"]:checked + label{
    border: solid 1px #012C63;
    background-color: #012C63;
    color: #ffffff;
} */

.boxed label:hover {
	cursor: pointer;
}

.boxed2 label:hover {
	cursor: pointer;
}

.exam-below-btn-container {
	margin: 3% 1%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.mark-4-rev-btn-container {
	margin: 5px 10px;
	text-align: center !important;
}

.mark-4-rev-btn-container2 {
	margin: 5px 10px;
}

.center-prompt {
	margin: 8px 10px;
}

.next-btn-container {
	margin: 5px 10px;
}

.not-answered-text,
.not-ans-mkd-4-rev {
	background-color: #ffc73626;
	color: #ffc736;
	font-weight: 600;
	width: 100%;
	text-align: center;
	padding: 5px 2px;
}
.ans-mkd-4-rev,
.answer-saved-text {
	background-color: #44b20033;
	color: #44b200;
	font-weight: 600;
	width: 100%;
	text-align: center;
	padding: 5px 2px;
}

.mark-4-rev-btn,
.mark-4-rev-btn:hover,
.mark-4-rev-btn:visited,
.mark-4-rev-btn:focus {
	border: 1px solid #012c63;
	color: #012c63;
	background-color: #ffffff;
	font-weight: 600;
	font-size: 1.1em;
	box-shadow: none;
	border-radius: 0;
	min-width: 150px;
}

.mark-4-rev-btn-2,
.mark-4-rev-btn-2:hover,
.mark-4-rev-btn-2:visited,
.mark-4-rev-btn-2:focus {
	border: 1px solid #ffffff;
	color: #ffffff;
	background-color: #012c63;
	font-weight: 600;
	font-size: 1.1em;
	box-shadow: none;
	border-radius: 0px !important;
	min-width: 150px;
}

.next-btn,
.next-btn:hover,
.next-btn:visited,
.next-btn:focus {
	border: 1px solid #ffffff;
	color: #ffffff;
	background-color: #012c63;
	font-weight: 600;
	font-size: 1.1em;
	box-shadow: none;
	border-radius: 0;
}

.fill-ups-inst {
	color: #757575;
	font-size: 1.1em;
}

.fill-ups-text::placeholder {
	font-size: 1.1em;
}

.fill-ups-text {
	font-size: 1.1em;
	padding: 4px 8px;
	width: 200px;
	border: 0.5px solid #000000;
	border-radius: 5px;
}

.right-panel {
	margin: 1.5% 50px;
	margin-right: 0px;
	/* padding: 8px; */
	background: #ffffff;
	/* border: 0.8px solid #909090; */
	/* height: 65vh; */
	/* overflow-y: auto; */
	/* overflow-x: hidden; */
	/* border-radius: 10px; */
}

.modal-header,
.modal-footer {
	border: none;
}

.modal-content {
	background: linear-gradient(98.09deg, rgba(255, 255, 255, 0.95) 0.79%, rgba(221, 221, 221, 0.9) 102.72%);
	padding: 5px 5px;
}

.mark-list {
	font-weight: 600;
	color: #012b60;
}

.close-modal-btn,
.close-modal-btn:hover,
.close-modal-btn:focus {
	color: #012b60;
	font-weight: 600;
	text-decoration: none;
	cursor: pointer;
}

.modal-list-img {
	width: 20px;
}

.not-visited-div-1 {
	background: linear-gradient(123.24deg, #c4c4c4 0%, rgba(196, 196, 196, 0.43) 123.1%);
	border: 1px solid #adadad;
	background-size: cover;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25), inset 3px 4px 6px 2px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 10px 15px;
	margin: 5px;
	text-align: center;
	color: #706f6f;
	font-weight: 500;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.not-visited-div-1-mobile {
	background: linear-gradient(123.24deg, #c4c4c4 0%, rgba(196, 196, 196, 0.43) 123.1%);
	border: 1px solid #adadad;
	background-size: cover;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25), inset 3px 4px 6px 2px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 10px 15px;
	margin: 5px;
	text-align: center;
	color: #706f6f;
	font-weight: 500;
	cursor: pointer;
}

.not-visited-div-ia {
	background: linear-gradient(123.24deg, #f4f4f4 0%, rgba(237, 237, 237, 0.43) 123.1%);
	border: 1px solid #cecece;
	background-size: cover;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07), inset 3px 4px 6px 2px rgba(144, 144, 144, 0.15);
	border-radius: 5px;
	padding: 10px 15px;
	margin: 5px;
	text-align: center;
	color: #b9b9b9;
	font-weight: 500;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.marks-info-box {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	color: #000000;
	font-weight: 600;
	font-size: 0.9em;
	border: 0.8px solid #909090;
	border-radius: 10px;
	padding: 12px;
}

.mark-info-div {
	margin: 6px 0px;
	padding: 2px;
	width: 160px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.not-answered-active-div-1 {
	background-image: url(../../../images/genericV2/not-ans-active.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #999999;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.not-answered-active-div-1-mobile {
	background-image: url(../../../images/genericV2/not-ans-active.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #999999;
	padding: 10px 15px;
	margin: 5px;
	cursor: pointer;
}

.not-answered-div-ia {
	background-image: url(../../../images/genericV2/not-ans-ia.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #b9b9b9;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.answered-active-div-1 {
	background-image: url(../../../images/genericV2/ans-active.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.answered-active-div-1-mobile {
	background-image: url(../../../images/genericV2/ans-active.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 10px 15px;
	margin: 5px;
	cursor: pointer;
}

.answered-div-ia {
	background-image: url(../../../images/genericV2/ans-ia.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.not-ans-mkd-active-div-1 {
	background-image: url(../../../images/genericV2/na-mkd-rev-active.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #999999;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.not-ans-mkd-active-div-1-mobile {
	background-image: url(../../../images/genericV2/na-mkd-rev-active.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #999999;
	padding: 10px 15px;
	margin: 5px;
	cursor: pointer;
}

.not-ans-mkd-div-ia {
	background-image: url(../../../images/genericV2/na-mkd-rev-ia.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #b9b9b9;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.ans-mkd-active-div-1 {
	background-image: url(../../../images/genericV2/ans-mkd-rev-active.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.ans-mkd-active-div-1-mobile {
	background-image: url(../../../images/genericV2/ans-mkd-rev-active.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 10px 15px;
	margin: 5px;
	cursor: pointer;
}

.ans-mkd-div-ia {
	background-image: url(../../../images/genericV2/ans-mkd-rev-ia.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 10px 15px;
	margin: 5px;
	/* height: 30px; */
	width: 45px;
	cursor: pointer;
}

.not-visited-div {
	background: linear-gradient(123.24deg, #c4c4c4 0%, rgba(196, 196, 196, 0.43) 123.1%);
	border: 1px solid #adadad;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25), inset 3px 4px 6px 2px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding: 5px 9px;
	margin: 5px;
	text-align: center;
	color: #706f6f;
	font-weight: 500;
	height: 30px;
	width: 35px;
}

.not-answered-active-div {
	background-image: url(../../../images/genericV2/not-ans-active.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #999999;
	padding: 5px 9px;
	margin: 5px;
	height: 30px;
	width: 35px;
}

.answered-active-div {
	background-image: url(../../../images/genericV2/ans-active.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 5px 9px;
	margin: 5px;
	height: 30px;
	width: 35px;
}

.not-ans-mkd-active-div {
	background-image: url(../../../images/genericV2/na-mkd-rev-active.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #999999;
	padding: 5px 9px;
	margin: 5px;
	height: 30px;
	width: 35px;
}

.ans-mkd-active-div {
	background-image: url(../../../images/genericV2/ans-mkd-rev-active.png);
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	color: #ffffff;
	padding: 5px 9px;
	margin: 5px;
	height: 30px;
	width: 35px;
}

.all-questions {
	display: flex;
	flex-wrap: wrap;
}

.mobile-marking-scheme {
	display: none;
}

.mobile-ques,
.mobile-ques:hover,
.mobile-ques:focus {
	font-size: 1.5em;
}

.phone-panel {
	display: none;
}

.exam-below-buttons-con {
	display: flex;
	justify-content: space-between;
	margin: 3% 1%;
}

.instmodal {
	color: #000000;
}

.modal-dialog-centered {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: calc(100% - (0.5rem * 2));
}

@media (min-width: 576px) {
	.modal-dialog-centered {
		min-height: calc(100% - (1.75rem * 2));
	}
}

.submit-modal-submit,
.submit-modal-submit:hover,
.submit-modal-submit:focus {
	color: #ffffff;
	background-color: #012c63;
	border-radius: 0px;
	width: 100px;
	font-weight: 600;
	margin: 20px;
}

.submitmodalclose,
.submitmodalclose:hover,
.submitmodalclose:focus {
	color: #012c63;
	background-color: #ffffff;
	border-radius: 0px;
	width: 100px;
	font-weight: 600;
	margin: 20px;
}

.modal-content {
	color: #000000;
}

@media (max-width: 767.98px) {
	.mobile-marking-scheme {
		display: block;
	}

	.right-panel {
		display: none;
	}

	.phone-panel {
		display: block;
	}
}

@media (max-width: 575.98px) {
	.nav-logo-img {
		width: 50vw;
		min-width: 150px;
	}

	.mobile-marking-scheme {
		display: block;
	}

	.right-panel {
		display: none;
	}

	.phone-panel {
		display: block;
	}

	.mark-4-rev-btn,
	.mark-4-rev-btn:hover,
	.mark-4-rev-btn:visited,
	.mark-4-rev-btn:focus,
	.mark-4-rev-btn-2,
	.mark-4-rev-btn-2:hover,
	.mark-4-rev-btn-2:visited,
	.mark-4-rev-btn-2:focus {
		font-size: 1.2em;
	}

	.submit-modal-submit,
	.submit-modal-submit:hover,
	.submit-modal-submit:focus {
		margin: 10px;
	}

	.submitmodalclose,
	.submitmodalclose:hover,
	.submitmodalclose:focus {
		margin: 10px;
	}
}

.general-inst-heading {
	font-weight: 500;
	text-decoration: underline;
}

.container-modal {
	padding-inline: 20px;
}

.question-pallete-heading {
	font-weight: 600;
}

.question-pallete-inst-content {
	font-weight: 400;
}

.Exam-summary-details {
	display: flex;
	overflow-y: auto !important;
	justify-content: space-between;
	border: 1px solid #c9c9c9;
}

.Exam-summary-box {
	text-align: center;
	font-size: 18px;
	color: #6d6d6d;
	border-right: 1px solid #c9c9c9;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.Exam-summary-box:last-child {
	border-right: none;
}

.Exam-number-data {
	margin-top: 10px;
	font-weight: 500;
	color: #000;
}

.Exam-summary-box-ex {
	text-align: center;
	font-size: 18px;
	color: #6d6d6d;
	border-right: 1px solid #c9c9c9;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.submission-details {
	text-align: center;
	font-weight: bold;
	font-size: 16px;
}

.yes-btn {
	text-align: center;
	padding: 5px;
	width: 100px;
	margin: 10px;
	border: 2px solid #e5e5e5;
	background-color: white;
	border-radius: 2px;
}

.no-btn {
	text-align: center;
	padding: 5px;
	width: 100px;
	margin: 10px;
	border: 2px solid #e5e5e5;
	background-color: white;
	border-radius: 2px;
}

.genericV2-question-tile {
	text-align: center;
	padding: 10px 15px;
	margin: 5px;
	width: 45px;
	cursor: pointer;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 90%;
}
.genericV2-question-tile.not-answered {
	background-image: url(../../../images/genericV2/not-ans-ia.png);
	color: #b9b9b9;
}
.genericV2-question-tile.not-answered.active {
	background-image: url(../../../images/genericV2/not-ans-active.png);
	color: #999999;
}
.genericV2-question-tile.not-visited {
	background: linear-gradient(123.24deg, #f4f4f4 0%, rgba(237, 237, 237, 0.43) 123.1%);
	border: 1px solid #cecece;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07), inset 3px 4px 6px 2px rgba(144, 144, 144, 0.15);
	border-radius: 5px;
	color: #b9b9b9;
	font-weight: 500;
}
.genericV2-question-tile.not-visited.active {
	background: linear-gradient(123.24deg, #c4c4c4 0%, rgba(196, 196, 196, 0.43) 123.1%);
	border-color: #adadad;
	color: #706f6f;
}
.genericV2-question-tile.answered {
	background-image: url(../../../images/genericV2/ans-ia.png);
	color: #b9b9b9;
}
.genericV2-question-tile.answered.active {
	background-image: url(../../../images/genericV2/ans-active.png);
	color: #ffffff;
}
.genericV2-question-tile.not-answered-marked {
	background-image: url(../../../images/genericV2/na-mkd-rev-ia.png);
	color: #b9b9b9;
}
.genericV2-question-tile.not-answered-marked.active {
	background-image: url(../../../images/genericV2/na-mkd-rev-active.png);
	color: #999999;
}
.genericV2-question-tile.answered-marked {
	background-image: url(../../../images/genericV2/ans-mkd-rev-ia.png);
	color: #ffffff;
}
.genericV2-question-tile.answered-marked.active {
	background-image: url(../../../images/genericV2/ans-mkd-rev-active.png);
	color: #ffffff;
}
