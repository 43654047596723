.save_btn {
  background-color: #6dbc1f;
  color: white;
  padding: 5px;
  width: 150px;
  border: 1px solid black;
  margin: 10px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.clear_btn {
  background-color: #f4f4f4;
  color: #575757;
  padding: 5px;
  margin: 10px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.review_btn {
  background-color: #dfc123;
  color: white;
  width: 200px;
  padding: 5px;
  margin: 10px;
  border: 1px solid black;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.review_next_btn {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  background-color: #2986db;
  color: white;
  padding: 5px;
  width: 200px;
  margin: 10px;
  border: 1px solid black;
}

.submit-con {
  background-color: #f1f1f1;
  padding: 8px;
}

.submit-con-btn {
  background-color: white;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  margin: 2px;
  width: 100px;
  border: 1px solid black;
}

.submit-con-btn-disable {
  background-color: white;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  margin: 2px;
  width: 100px;
  border: 1px solid black;
  cursor: not-allowed;
}

.submit-con-btn-O {
  background-color: #6dbc1f;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  padding: 5px;
  margin: 2px;
  color: white;
  border: 0px solid black;
  float: right;
  width: 100px;
}

.buttons-container {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  border-top: 1px solid #eeeeee;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .buttons-container {
    position: static;
  }
}
