.genericV2-myclassroom-logo {
  background-image: url(../../../images/genericV2/myclassroom-logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 232px;
  height: 40px;
}

@media (max-width: 530px) {
  .genericV2-myclassroom-logo {
    background-image: url(../../../images/genericV2/myclassroom-logo-mobile.svg);
    width: 40px;
  }
}
