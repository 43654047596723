.profileLocked {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 75.43px - 32px);
    padding: 44px 0;
    overflow-x: hidden;
}

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 80vw;
}

.lockedImage {
    max-width: 389px;
    margin-bottom: 28px;
    margin-top: -28px;
}

.helperText {
    font-family: Montserrat;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    color: #a8a8a8;
    margin-bottom: 28px;
}

.outstandingFee {
    border: 1px solid #012c63;
    height: 59px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vh;
    max-width: 310px;
    letter-spacing: 0.02em;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #161616;
    margin-bottom: 28px;
}

.outstandingFeeAmount {
    font-size: 18px;
    color: #000;
}

.contactCounsellor {
    color: #1C3687;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.02em;
}

.profileLockedFooter {
    height: 32px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 12px;
    text-align: center;
    width: 100%;
}

.profileLockedFooterLink {
    
}

@media only screen and (max-width: 991px) {
    .profileLocked {
        min-height: calc(100vh - 70.5px - 48px);
    }

    .lockedImage {
        max-width: 213px;
        margin-bottom: 20px;
        margin-top: 0;
    }

    .helperText {
        font-size: 14px;
        line-height: 15px;
    }

    .contactCounsellor {
        font-size: 16px;
        line-height: 1;
    }

    .outstandingFee {
        font-size: 14px;
        line-height: 1;
        height: 48px;
        color: #727272;
    }

    .profileLockedFooter {
        height: 48px;
    }
}
