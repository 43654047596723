.Mark_scheme_con {
  width: 100%;
  height: auto;
  margin-top: 10px;
  padding: 8px;
  border-style: dashed;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.summary-info {
  display: flex;
  align-items: center;
  padding: 12px;
}

@media screen and (max-width: 767px) {
  .summary-info {
    width: 100% !important;
  }
}
