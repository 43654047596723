.attendance_header_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 21px;
	line-height: 27px;
	color: #09090a;
	margin: 2rem auto;
	text-align: center;
}

.dashboard_attendance_card {
	margin: 2rem;
	padding: 1.5rem;
	background-color: var(--attendance-card);
	border-radius: 8px;
}

.dashboard_blur {
	position: absolute;
	inset: -1px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 8px;
	backdrop-filter: blur(7px);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.dashboard_attendance_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #09090a;
	margin-bottom: 1rem;
}

.dashboard_attendance_text2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #09090a;
	margin-bottom: 0;
}

.dashboard_attendance_text3 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 27px;
	color: #09090a;
}

.attendance_mark_wrapper {
	text-align: center;
	position: relative;
	width: fit-content;
	margin-left: auto;
	margin-right: auto;
}

.attendance_mark_image {
	width: 12rem;
}

.attendance_mark_text1 {
	font-family: "Molle";
	font-style: normal;
	font-weight: 400;
	font-size: 31.1192px;
	line-height: 39px;
	letter-spacing: 0.02em;
	color: #000000;
	position: absolute;
	margin-bottom: 0;
	top: 2.5rem;
	left: 50%;
	transform: translate(-50%, 0);
}

.attendance_mark_text2 {
	font-family: "Molle";
	font-style: normal;
	font-weight: 400;
	font-size: 31.1192px;
	line-height: 39px;
	letter-spacing: 0.02em;
	color: #000000;
	position: absolute;
	margin-bottom: 0;
	top: 7.5rem;
	left: 50%;
	transform: translate(calc(-50% + 5px), 0);
}

.attendance_divider {
	position: absolute;
	top: 2.5rem;
	left: 50%;
	border: 0.9px solid #000000;
	background-color: #000000;
	transform: rotate(-15deg) translate(-32px, -8px);
	width: 65px;
	top: 6.8rem;
}

