.mb_1 {
	margin-bottom: 5px !important;
}
.mb_2 {
	margin-bottom: 10px !important;
}
.mb_3 {
	margin-bottom: 20px !important;
}
.column {
	display: flex;
	flex-direction: column !important;
}
.row {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.cursor_pointer {
	cursor: pointer !important;
}

.row_flex_start {
	display: flex;
	align-items: flex-start;
}
.align_item_center {
	align-items: center !important;
}
.justify_content_between {
	justify-content: space-between !important;
}
.justify_content_center {
	justify-content: center !important;
}

.main_div {
	background: #f5f5f5;
	min-height: 100vh;
	padding: 3rem 8rem 0rem 8rem;
	text-align: justify;
	font-family: "Montserrat" !important;
}
.container {
	background: #ffffff;
	border-radius: 8px;
	padding: 1rem 2rem 2rem;
	margin-top: 4rem;
	scroll-margin-top: 100px;
}
.card {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 20px;
	gap: 10px;
	margin-top: 10px;
}
.dashboard_content_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	color: #0e0d0d;
	margin-bottom: 0;
}
.see_all {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 200px;
}
.see_all.disabled {
	cursor: not-allowed;
	opacity: 0.5;
}
.chapter_div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px 14px;
	gap: 10px;
	background: #ffffff;
	border-radius: 30px;
	margin-right: 15px;
	white-space: nowrap;
}
.chapterDetails {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 21px;
	color: #201f1f;
}
.text > * {
	margin-bottom: 0;
}
.text.small {
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.04em;
	color: #121212;
}
.video_div {
	display: flex;
	flex-direction: column;
	align-items: baseline;
	scroll-snap-align: start;
}
.image_div {
	border-radius: 16px;
	margin-bottom: 10px;
	width: 320px;
	height: 176px;
	object-fit: cover;
}

.solution_div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	background: #ffffff;
	border: 1px solid #d9d9d9;
	border-radius: 8px;
	margin-right: 20px;
	margin-top: 8px;
	cursor: pointer;
	min-height: 70px;
	min-width: 190px !important;
}
.solution_div.see_all {
	min-width: 0px !important;
}
.headerComponentDiv {
	background: #ffffff;
	border-radius: 16px;
	padding: 18px;
	padding-left: 30px;
	gap: 15px;
	display: flex;
	margin-bottom: 20px;
}
.filter_title {
	display: flex;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #100f0f;
	min-width: 105px;
	margin-bottom: 8px;
}
.filter_title.disabled {
	color: #999999 !important;
}
.class_chip {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	background: white;
	border-radius: 24px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	border: 1px solid #939393;
	color: #939393;
	margin-right: 12px;
	margin-bottom: 8px;
	cursor: pointer;
}
.class_chip.chapter {
	border: none;
	color: #201f1f;
	margin-right: 12px;
	margin-bottom: 8px;
	margin-bottom: 0px;
}
.class_chip.selected {
	background: #deedff;
	border-color: #deedff;
	color: #012c63;
	cursor: default;
}
.filter_container {
	gap: 20px;
	background: #ffffff;
	border-radius: 8px;
	font-family: "Montserrat" !important;
	padding: 20px 50px;
}
.filter_row {
	display: flex;
	gap: 20px;
	font-family: "Montserrat" !important;
	align-items: center;
	margin-block: 15px;
}
.subject_div {
	display: block;
	align-items: center;
	padding: 16px 0px 16px 28px;
	border-radius: 8px;
	margin: 40px 0px 12px 0px;
}
.grey400 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	color: #939393;
}
.text_solution_button {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 16px;
	background: #eaf3ff;
	border-radius: 24px;
	gap: 10px;
	font-family: "Montserrat";
	text-decoration-line: underline;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	color: #0057bc;
	cursor: pointer;
}
.text_solution_button_disabled {
	opacity: 0.55;
	cursor: not-allowed;
}
.chapter_col {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.seeAll_Card {
	display: flex;
	flex-direction: column;
	background: #f4f4f4;
	border-radius: 8px;
	min-width: 208px;
	font-size: 22px;
	line-height: 36px;
	color: #012c63;
	justify-content: center;
	align-items: center;
	scroll-snap-align: start;
	cursor: pointer;
	height: 176px;
}

.no_image_div {
	border-radius: 16px;
	margin-bottom: 10px;
	width: 320px;
	height: 176px;
	background: #131313;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 40px;
	line-height: 50px;
	text-align: center;
	letter-spacing: 0.04em;
	color: #ffffff;
	padding: 6px 2px;
}

@media (max-width: 991px) {
	.text {
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: #201f1f;
	}

	.row_flex_start {
		align-items: center;
	}

	.dashboard_content_header {
		font-size: 15px;
		line-height: 20px;
		font-weight: 500;
	}

	.see_all {
		font-size: 14px;
	}

	.image_div {
		border-radius: 16px;
		margin-bottom: 10px;
		width: 201px;
		height: 111px;
	}

	.no_image_div {
		border-radius: 16px;
		margin-bottom: 10px;
		width: 201px;
		height: 111px;
		font-weight: 600;
		font-size: 22px;
		line-height: 45px;
	}

	.seeAll_Card {
		height: 111px;
		min-width: 201px;
		font-size: 18px;
	}

	.solution_div {
		gap: 10px;
		width: max-content !important;
		margin-right: 0px !important;
		min-height: 54px;
	}
	.solution_div.see_all {
		min-width: 190px !important;
	}
	.chapterDetails {
		display: block;
	}
	.tag {
		margin-bottom: 4px;
	}

	.filter_container {
		padding: 20px;
	}
	.filter_title {
		min-width: 75px;
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
	}
	.filter_row {
		display: block;
	}
	.main_div {
		padding: 1.5rem 0;
	}
	.card {
		margin-top: 0;
	}
	.container {
		padding: 1rem;
	}
	.class_chip {
		padding: 4px 8px;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
	}
}

@media (max-width: 575.98px) {
	.solution_div {
		width: 100% !important;
		margin-top: 6px;
	}
}
