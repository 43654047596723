.ant-select.chapter_select_filter .ant-select-selector {
	background: #fcfcfc;
	border-radius: 22px;
	height: 42px;
	align-items: center;
}

.ant-select.chapter_select_filter .ant-select-selection-item {
	line-height: 40px;
}

.chapter_select_filter .ant-select-clear {
	background: #f9f9f9;
	border: 1px solid #e2e2e2;
}
.ant-select-selection-placeholder {
	font-family: "Montserrat";
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	display: flex;
	align-items: center;
}
