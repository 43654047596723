.button {
	background: #1f3061;
	box-shadow: 0px 4px 12px rgba(31, 48, 97, 0.25);
	border-radius: 26px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;
	width: 100%;
	outline: none;
	border: none;
	height: 40px;
	margin-top: 2.5rem;
}

.smallButton {
	height: 38px;
	font-size: 14px;
	width: 90%;
}

.button:disabled {
	background: #b4c0d0;
	cursor: not-allowed;
}
