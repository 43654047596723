.detail-para {
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
}

.Exam-summary-details {
  display: flex;
  overflow-y: scroll;
  justify-content: space-between;
}

.Exam-summary-box {
  text-align: center;
  font-size: 18px;
  color: #6d6d6d;
  border-right: 1px solid #c9c9c9;
  padding: 10px;
}

.Exam-number-data {
  margin-top: 10px;
}

.Exam-summary-box-ex {
  text-align: center;
  font-size: 18px;
  color: #6d6d6d;
  border-right: 1px solid #c9c9c9;
  padding: 10px;
}

.submission-details {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.yes-btn {
  text-align: center;
  padding: 5px;
  width: 100px;
  margin: 10px;
  border: 2px solid #e5e5e5;
  background-color: white;
  border-radius: 2px;
}

.no-btn {
  text-align: center;
  padding: 5px;
  width: 100px;
  margin: 10px;
  border: 2px solid #e5e5e5;
  background-color: white;
  border-radius: 2px;
}
