.navbar-nav {
	display: flex;
	align-items: center;
}

.nav-user-name {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 17px;
	line-height: 20px;
	color: #0a0909;
	margin-bottom: 0;
	margin-left: 1rem;
	width: 80px;
	max-width: 80px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.myclassroom_logo {
	width: 70%;
	margin-top: 5px;
}

.nav-notification {
	background: #ffffff;
	border: 1px solid #f6f6f6;
	box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
	border-radius: 12px;
	padding: 1rem;
	margin-right: 1.5rem;
	cursor: pointer;
}

.navbar-hamburger {
	background: #ffffff;
	border: 1px solid #f6f6f6;
	box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	padding: 1rem;
	width: 4rem;
	display: none;
}

.nav-notification-mobile {
	background: #ffffff;
	border: 1px solid #f6f6f6;
	box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
	border-radius: 12px;
	padding: 1rem;
	margin-right: 1.5rem;
	cursor: pointer;
	width: 4.5rem;
	display: none;
}

.nav-attendance-mobile {
	display: flex;
	background: #ffffff;
	box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
	border-radius: 8px;
	padding: 1rem;
	display: none;
	width: fit-content;
	margin-right: 1.2rem;
}

.nav-attendance-mobile-text1 {
	margin-bottom: 0;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	align-items: center;
	color: #585858;
}

.nav-attendance-mobile-text2-wrapper {
	background: #e7edff;
	border-radius: 78px;
	padding: 0.5rem 0.8rem;
	margin-left: 1rem;
}

.nav-attendance-mobile-text2 {
	margin-bottom: 0;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	display: flex;
	align-items: center;
	color: #344881;
}

.layout_container {
	margin: 0 30px 0 30px !important;
}

.nav-mobile-wrapper {
	display: none;
	cursor: pointer;
}

.hamburger-padding {
	padding: 40px 20px;
	overflow: auto;
}

.paper-stylingg {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
}

.user-details-ham {
	display: flex;
	gap: 10px;
	align-items: center;
	padding-bottom: 40px;
}

.user-avatar-image {
	width: 80px;
	border-radius: 50%;
	height: 80px;
}

.userName {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 22px;
	color: #0a0909;
}

.userNumber {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 15px;
	color: #9c9898;
	margin-top: 0.5rem;
}

.flexBetween {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.courseHeading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 17px;
	display: flex;
	align-items: center;
	color: #0c0b0b;
	margin-top: 1.5rem;
}

.seeAllCourse {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 5px 10px;
	gap: 10px;
	border: 1px solid #1f3061;
	border-radius: 26px;
	font-weight: 400;
	font-size: 16px;
	line-height: 17px;
	color: #000000;
	position: absolute;
	top: 8px;
	right: 0px;
}

.containerBox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-block: 20px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;

	color: #000000;
}

/* Hide the browser's default radio button */
.containerBox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
	position: absolute;
	top: -2px;
	left: 0;
	height: 25px;
	width: 25px;
	background-color: #fff;
	border-radius: 50%;
	border: 1px solid #012c63;
}

/* On mouse-over, add a grey background color */
.containerBox:hover input ~ .checkmark {
	background-color: #012c630f;
}

/* When the radio button is checked, add a white background */
.containerBox input:checked ~ .checkmark {
	background-color: #ffffff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerBox input:checked ~ .checkmark:after {
	display: block;
}

/* Style the indicator (dot/circle) */
.containerBox .checkmark:after {
	top: 4.1px;
	left: 4.2px;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #012c63;
}

.navigationLinks {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 18px 0px;
}

.linkItem {
	display: flex;
	align-items: center;
	gap: 15px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 17px;
	color: #0c0b0b;
	position: relative;
}

.linkItem {
	color: #0c0b0b;
}
.linkItem:hover {
	color: #0c0b0b;
}

.logoutHamImage {
	background-color: #0c0b0b33 !important;
	border-radius: 50%;
	padding: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.popup-container-batch {
	padding: 30px;
	min-width: 270px;
}

.popupHeading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 132.6%;
	text-align: center;
	text-transform: capitalize;
}

.headerFlex {
	display: flex;
	gap: 40px;
	justify-content: space-between;
	align-items: center;
}

.closeBtn {
	color: #93959d;
	font-size: 20px;
}

.batchList {
	margin-top: 20px;
}

.batchName {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 132.6%;
	text-transform: capitalize;
	color: #000000;
	cursor: pointer;
	padding: 10px 20px;
}

.batchName.selectedBatch {
	background: #f5edff;
	border-radius: 10px;
}

.buttonContainerPopup {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 20px;
}

.saveBtnPopup {
	padding: 10px 30px;
	gap: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	background: #012c63;
	border-radius: 33px;
	width: max-content;
	font-weight: 400;
	font-size: 16px;
	line-height: 132.6%;
}

.aits-popup-top-img {
	width: 100%;
}

.benefits-heading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: #0e0e0e;
	margin-block: 10px;
}

.courseBenefits {
	display: inline-grid;
	grid-template-columns: calc(33% - 20px) calc(33% - 20px) calc(33% - 20px);
	gap: 30px;
}

.benefitsEachCourse {
	width: 100%;
	background-color: #ffffff;
	padding: 25px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500 !important;
	font-size: 16px;
	text-align: center;
	color: #4d4d4d;
}

.onlineCoursePopup {
	background-color: #f4f4f4;
	border-radius: 10px;
}

.bottomCtaContainer {
	background-color: #edf1ff;
	box-shadow: 0px -4px 40px rgba(0, 0, 0, 0.03);
	border-radius: 20px;
	padding: 15px 25px;
	display: flex;
	gap: 10px;
	margin-top: 20px;
	justify-content: space-between;
	align-items: center;
}
.CtaContainerText {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500 !important;
	font-size: 16px;
	color: #012c63;
}

.popupHurrayHeading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	color: #09090a;
}

.popupLightHeading {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	color: #09090a;
	margin-block: 10px;
}

.redirectingLine {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #09090a;
}

.redirectingSuccessGif {
	width: 100%;
	margin-block: 10px;
	padding: 5px 30px;
}

.redirectingErrorGif {
	width: 80%;
	margin-block: 10px;
	padding: 5px 30px;
}

.nav-profile-pic {
	min-width: 40px;
	max-width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f0f8ff;
}

.user-profile-pic {
	min-width: 80px;
	max-width: 80px;
	height: 80px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f0f8ff;
	font-size: 2.5rem;
}

.locked-container {
	background: #ffffff;
	border-radius: 6px;
	width: 26px;
	height: 26px;
	margin-left: 6px;
	display: flex;
	justify-content: center;
}

@media screen and (max-width: 991px) {
	.myclassroom_logo {
		width: 160px;
		margin-top: 0;
		margin-left: 12px;
	}

	.nav-notification-mobile {
		margin-right: 0;
	}

	.nav-mobile-wrapper {
		display: flex;
		position: absolute;
		right: 15px;
	}

	.container-fluid::before {
		content: none;
	}

	.navbar-header::before {
		content: none;
	}

	.layout_container {
		margin: 0 15px 0 15px !important;
	}

	.navbar-header {
		display: flex;
		justify-content: space-between;
	}

	.navbar-hamburger {
		display: block;
	}

	.nav_heading {
	}

	.nav-attendance-mobile {
		display: flex;
	}

	.nav-notification-mobile {
		display: block;
	}

	.courseBenefits {
		display: inline-grid;
		grid-template-columns: calc(50% - 6px) calc(50% - 6px);
		gap: 12px;
	}

	.benefitsContainer {
		padding: 20px;
		padding-bottom: 0;
	}

	.locked-container {
		border: 1px solid #f6f6f6;
		box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
	}
}

@media screen and (max-width: 650px) {
	.myclassroom_logo {
		width: 90%;
		max-width: 120px;
		margin-top: 0;
		margin-left: 6px;
	}

	.navbar-header:after {
		display: none;
	}

	.popup-container-batch {
		padding: 15px 10px;
	}

	.benefitsEachCourse {
		width: 100%;
		background-color: #ffffff;
		padding: 15px;
		border-radius: 10px;
		font-size: 14px;
	}

	.bottomCtaContainer {
		flex-direction: column;
		align-items: flex-start;
	}

	.CtaContainerText {
		font-size: 14px;
	}

	.nav-notification-mobile {
		margin-right: 0;
		width: 38px;
		height: 38px;
		padding: 8px;
	}

	.nav-mobile-wrapper {
		position: relative;
		align-items: center;
		right: 0;
	}

	.container-fluid::before {
		content: none;
	}

	.navbar-header::before {
		content: none;
	}

	.layout_container {
		margin: 0 15px 0 15px !important;
	}

	.navbar-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.navbar-hamburger {
		display: flex;
		height: 38px;
		width: 38px;
		min-width: 38px;
		padding: 0;
		align-items: center;
		justify-content: center;
	}

	.nav_heading {
		width: 100%;
	}

	.nav-attendance-mobile {
		display: flex;
		padding: 6px;
		align-items: center;
		height: fit-content;
		margin-right: 10px;
	}

	.nav-notification-mobile {
		display: block;
	}

	.nav-attendance-mobile-text1 {
		margin-bottom: 0;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		display: flex;
		align-items: center;
		color: #585858;
	}

	.nav-attendance-mobile-text2-wrapper {
		background: #e7edff;
		border-radius: 78px;
		padding: 2px 4px;
		margin-left: 1rem;
		display: flex;
		align-items: center;
		height: fit-content;
	}

	.nav-attendance-mobile-text2 {
		margin-bottom: 0;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 400;
		font-size: 10px;
		line-height: 12px;
		display: flex;
		align-items: center;
		color: #344881;
	}
}
