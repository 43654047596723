#quess-con {
  padding-right: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.question_number_para {
  font-size: 18px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.option_para {
  font-size: 14px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

.radio-con {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
}

.arrow_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-30px);
  right: 0px;
  height: 30px;
  background-color: black;
  cursor: pointer;
}

.question-con {
  margin-top: 20px;
}
.ques-img-container {
  margin: 1em 0;
}

@media screen and (max-width: 991px) {
  .arrow_btn {
    display: none !important;
  }
  .question-con {
    padding: 0 16px;
  }

  #quess-con {
    padding-right: 0px;
    overflow: auto;
  }
}
