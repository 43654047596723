.spinner.spinner-white:before {
    border: 2px solid #ffffff;
    border-right: 2px solid transparent;
}

.spinner:before {
    animation: animation-spinner 0.5s linear infinite;
}

.spinner {
    position: relative;
}

.spinner:before {
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
}

.spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    border: 2px solid #d6d6e0;
    border-right: 2px solid transparent;
}

@-webkit-keyframes animation-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes animation-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}