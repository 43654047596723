.d-flex {
	display: flex;
}
.d-flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.flex-grow {
	flex-grow: 1;
}

.align-items-center {
	align-items: center;
}

.justify-content-center {
	justify-content: center;
}
.justify-content-between {
	justify-content: space-between !important;
}
.justify-content-centre {
	justify-content: center !important;
}

.dashboard_container {
	padding: 3rem 3.6rem 0rem;
}

.dashboard_user_meta {
	background: #edf1ff;
	border: 2px solid #eff3ff;
	border-radius: 8px;
	padding: 1.5rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dashboard_user_meta_mobile {
	background: #edf1ff;
	border: 2px solid #eff3ff;
	border-radius: 8px;
	padding: 1.5rem;
}

.dashboard_user_avatar_container {
	width: 100px;
	min-width: 100px;
	height: 100px;
	background: #ffffff;
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dashboard_user_avatar {
	width: 5rem;
	min-width: 5rem;
}

.dashboard_student_meta_container {
	margin: 0 2rem;
}

.dashboard_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 19px;
	line-height: 32px;
	color: #09090a;
	margin-bottom: 0;
}

.dashboard_text2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #09090a;
	margin-bottom: 0;
}

.dpp_meta_container {
	background: #ffffff;
	border-radius: 8px;
	height: 80%;
	padding: 1.8rem 2.5rem;
	display: flex;
	align-items: center;
	flex: 0.25;
}

.dpp_meta_container .ant-progress-text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 17px;
	color: #012c63;
}

.dpp_meta_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 27px;
	text-align: center;
	color: #1f3061;
	margin-bottom: 0;
	margin-left: 2rem;
}

.dpp_button_container {
	justify-content: center;
	display: flex;
}

.dpp_button {
	padding: 0 1.5rem;
	background: #1f3061;
	box-shadow: 0px 4px 12px rgba(31, 48, 97, 0.25);
	border-radius: 26px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	color: #ffffff;

	outline: none;
	border: none;
	height: 40px;
}

.dashboard_layout {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}

.dashboard_nav {
	background: #ffffff;
	border-radius: 8px;
	width: 250px;
	min-width: 250px;
	height: calc(100vh - 10rem - 78px);

	position: sticky;
	/* top: 26rem; */
	top: 10rem;
}

.dashboard_attendance {
	background: #ffffff;
	border-radius: 8px;
	width: 250px;
	min-width: 250px;
	min-height: calc(100vh - 10rem - 78px);
	height: fit-content;
	position: sticky;
	top: 10rem;
	overflow: auto;
}

.dashboard_content {
	width: calc(100% - 500px);
	padding: 0 1.5rem;
}

.dasboard_nav_text {
	font-family: "Montserrat";
	font-style: normal;
	font-size: 18px;
	line-height: 36px;
	text-align: center;
	font-weight: 400;
	color: #0e0d0d;
	margin-bottom: 0;
	margin-left: 1rem;
}

.dasboard_nav_text.active {
	font-weight: 500;
	color: #ffffff;
}

.dasboard_nav_item_wrapper {
	display: flex;
	background: #ffffff;
	padding: 1rem 1.5rem;
	border-radius: 60px 0px 0px 50px;
	margin-left: 3rem;
	cursor: pointer;
	margin-top: 2rem;
	align-items: center;
}

.active.dasboard_nav_item_wrapper {
	background: #012c63;
}

.dashboard_nav_coming_soon {
	background: #f5edff;
	border-radius: 31px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #0e0d0d;
	position: absolute;
	padding: 0.3rem 1rem;
	margin-left: 1rem;
}

.dashboard_mpp_container {
	background: #ffffff;
	border-radius: 8px;
	padding-top: 1rem;
	scroll-margin-top: 100px;
}

.dashboard_content_header_container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dashboard_content_header_container_test {
	display: flex;
	align-items: center;
}

.dashboard_content_active {
	background: #171e5a;
	border-radius: 0px 8px 8px 0px;
	width: 0.4rem;
	height: 32px;
	position: absolute;
}

.dashboard_content_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 36px;
	color: #0e0d0d;
	margin-bottom: 0;
	margin-left: 2rem;
}

.dashboard_content_view_all {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 36px;
	color: #012c63;
	margin-bottom: 0;
	cursor: pointer;
	margin-right: 2rem;
	background: transparent;
	border: none;
	outline: none;
	white-space: nowrap;
}

.dashboard_view_all_card {
	display: flex;
	flex-direction: column;
	min-height: calc(100% - 2rem);
	background: #f4f4f4;
	border-radius: 8px;
	min-width: 208px;
	font-size: 22px;
	line-height: 36px;
	color: #012c63;
	justify-content: center;
	align-items: center;
	margin: 1rem 0;
	scroll-snap-align: start;
	cursor: pointer;
}

.dashboard_mpp_card {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 1.5rem;
	margin: 1rem 0;
	min-width: 400px;
	max-width: 400px;
	display: flex;
	flex-direction: column;
	height: calc(100% - 2rem);
}

.mpp_card_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	color: #454545;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.mpp_subject_chapter_wrapper {
	display: flex;
	align-items: center;
}

.mpp_card_subject {
	background: #e0faff;
	border-radius: 70px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 17px;
	text-align: center;
	color: #0e0d0d;
	padding: 0.5rem 1rem;
	width: fit-content;
	white-space: pre;
}

.mpp_card_chapter {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	color: #454545;
	margin-left: 1rem;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.mpp_tag_wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.mpp_tag_seperator {
	width: 4px;
	height: 4px;
	background: #747474;
	border-radius: 100%;
	margin: 0 0.8rem;
}

.mpp_card_tags {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.015em;
	color: #747474;
}

.dpp_result_button {
	background: #ebffe8;
	border: 1px solid #88be7f;
	border-radius: 20px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: center;
	width: 90%;
	margin-top: 2.5rem;
	outline: none;
	height: 38px;
	color: #138400;
}

.dpp_start_button {
	background: #012c63;
	border: 1px solid #012c63;
	border-radius: 20px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: center;
	width: -webkit-fill-available;

	outline: none;
	height: 38px;
	margin: 2rem 3rem 0 3rem;

	color: #ffffff;
}

.dashboard_mpp_wrapper {
	display: flex;
	overflow: auto;
	padding-right: 2rem;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard_mpp_wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard_mpp_wrapper {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.mpp_card_status {
	background: var(--mpp_card_status);
	color: #fff;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	height: 20px;
	letter-spacing: 0.02em;
	line-height: 18px;
	padding: 2px 0.5rem 2px 1.3rem;
	position: relative;
	/* width: 120px; */
	margin-bottom: 0;
	float: right;
	left: 1.5rem;
	top: -1.5rem;
	border-top-right-radius: 5px;
}

.mpp_card_status:after {
	border-bottom: 10px solid transparent;
	border-right: 10px solid #fff;
	border-top: 10px solid transparent;
	bottom: 0;
	content: "";
	height: 0;
	position: absolute;
	/* right: -1px; */
	width: 0;
	left: -1px;
	transform: rotate(180deg);
}

.mpp_card_status_wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.mpp_card_status_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: var(--mpp_card_status_text);
}

.mpp_status_img_completed {
	width: 1.5rem;
	margin-right: 0.3rem;
}

.mpp_status_img_pending {
	width: 1.2rem;
	margin-right: 0.3rem;
	margin-bottom: 0.2rem;
}

.faculty_mpp_wrapper_dummy {
	height: 40px;
}

.faculty_mpp_wrapper {
	background: #fff5ee;
	border-radius: 50px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.015em;
	color: #0e0d0d;
	padding: 0.5rem 1.5rem;
	width: fit-content;
	white-space: nowrap;
	margin: 0 0 1rem 0;
	max-width: 100%;
}

.faculty_greeting_img {
	margin-right: 0.7rem;
}

.arrow_wrapper {
	padding: 1.2rem 1.5rem;
	display: flex;
	justify-content: flex-end;
}

.left_arrow_indicator {
	margin-right: 1.5rem;
	cursor: pointer;
}

.right_arrow_indicator {
	cursor: pointer;
}

.dashboard_test_container {
	background: #ffffff;
	border-radius: 8px;
	padding-top: 1rem;
	margin-top: 4rem;
	scroll-margin-top: 100px;
}

.dashboard_test_wrapper {
	display: flex;
	overflow: auto;
	padding-right: 2rem;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
}

.dashboard_test_card {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 1.5rem;
	margin: 1rem 0;
	min-width: 340px;
	max-width: 340px;
	height: calc(100% - 2rem);
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.test_card_status {
	padding: 0 0.7rem;
}

.test_card_chapters {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
	letter-spacing: 0.015em;
	color: #454545;
	line-height: 2.5rem;
	white-space: nowrap;
}

.test_card_status_wrapper {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.test_card_status {
	background: var(--test_card_status);
	color: #fff;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	height: 20px;
	letter-spacing: 0.02em;
	line-height: 18px;
	padding: 2px 0.5rem 2px 1.3rem;
	position: relative;
	/* width: 120px; */
	margin-bottom: 0;
	float: right;
	left: 1.5rem;
	top: -1.5rem;
	border-top-right-radius: 5px;
}

.test_card_status:after {
	border-bottom: 10px solid transparent;
	border-right: 10px solid #fff;
	border-top: 10px solid transparent;
	bottom: 0;
	content: "";
	height: 0;
	position: absolute;
	/* right: -1px; */
	width: 0;
	left: -1px;
	transform: rotate(180deg);
}

.test_card_status_text {
	padding: 0 0.7rem;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	color: var(--test_card_status_text);
}

.test_card_header {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	color: #454545;
	margin-top: var(--test_card_header_margin);
}

.test_card_syllabus_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 17px;
	/* identical to box height */
	color: #454545;
}

.test_card_subject_wrapper {
	display: flex;
	column-gap: 0.4rem;
	flex-wrap: wrap;
}

.test_card_subject {
	background: var(--test_card_subject_bg);
	border-radius: 82px;
	color: #0e0d0d;
	padding: 0.25rem 1rem;
	margin-bottom: 1rem;
}

.test_card_chapter_wrapper {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 55px;
}

.test_card_chapter_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* identical to box height */
	color: #454545;
}

.test_card_test_window_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 17px;
	/* identical to box height */

	letter-spacing: 0.02em;

	color: #000000;
}

.test_window_time_wrapper {
	display: flex;
	justify-content: space-between;
}

.test_window_time_container {
	background: #f7f7f7;
	border-radius: 8px;
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 1rem;
}

.test_window_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 20px;
	/* identical to box height */

	display: flex;
	align-items: center;
	text-align: right;
	letter-spacing: 0.06em;

	color: #505050;
	margin-bottom: 0;
}

.test_window_text2 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 17px;
	/* identical to box height */

	display: flex;
	align-items: center;
	text-align: right;
	letter-spacing: 0.06em;

	color: #505050;
	margin-bottom: 0;
}

.test_window_duration_container {
	background: #f7f7f7;
	border-radius: 8px;
	padding: 1rem;
}

.test_chapter_seperator {
	width: 1px;
	height: 18px;
	background: #bababa;
	border-radius: 46px;
	margin: 0 0.8rem;
}

.test_chapters_more {
	background: #f1f5ff;
	border: 1px solid #b5c8ff;
	border-radius: 74px;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #1f3061;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard_test_wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard_test_wrapper {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.dashboard_class_container {
	background: #ffffff;
	border-radius: 8px;
	padding-top: 1rem;
	margin-top: 4rem;
	scroll-margin-top: 100px;
}

.dashboard_class_wrapper {
	display: flex;
	padding-right: 2rem;
	overflow: auto;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
}

.dashboard_test_module_wrapper {
	display: flex;
	padding: 2rem;
	flex-wrap: wrap;
	grid-gap: 2rem;
	position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dashboard_class_wrapper::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dashboard_class_wrapper {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.dashboard_class_card {
	background: #ffffff;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	margin: 1rem 0;
	min-width: 300px;
	max-width: 300px;
	height: calc(100% - 2rem);
	display: flex;
	flex-direction: column;
}

.class_card_faculty_wrapper {
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	height: 100px;
	width: 100%;
	background: #524453;
	position: relative;
}

.class_card_status {
	background: var(--class_card_status);
	color: #fff;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	height: 20px;
	letter-spacing: 0.02em;
	line-height: 18px;
	padding: 2px 1.5rem 2px 1rem;
	position: relative;
	width: fit-content;
	margin-bottom: 0;
	border-top-left-radius: 5px;
}

.class_card_status:after {
	border-bottom: 10px solid transparent;
	border-right: 10px solid #524453;
	border-top: 10px solid transparent;
	bottom: 0;
	content: "";
	height: 0;
	position: absolute;
	right: -1px;
}

.class_card_status_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	letter-spacing: 0.02em;
	text-transform: capitalize;
	color: var(--class_card_status_text);
}

.class_faculty_wrapper {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	align-items: flex-end;
}

.class_faculty_info_container {
	flex: 0.6;
	padding-bottom: 1rem;
}

.class_faculty_img_container {
	flex: 0.4;
	text-align: right;
}

.class_card_faculty_name {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 12px;
	/* identical to box height, or 67% */

	display: flex;
	align-items: center;
	text-align: right;
	letter-spacing: 0.01em;

	color: #ffffff;
	margin-bottom: 0;

	display: flex;
	justify-content: flex-end;
}

.class_card_faculty_divider {
	width: 100%;
	background: #53b974;
	margin: 0.5rem 0;
	height: 0.15rem;
}

.class_card_time_wrapper {
	display: flex;
	column-gap: 0.8rem;
	align-items: center;
	justify-content: flex-end;
}

.class_card_time_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 17px;
	/* identical to box height */
	color: #ffffff;
	margin-bottom: 0;
}

.class_card_time_text2 {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 8px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 12px;
	/* identical to box height, or 86% */

	display: flex;
	align-items: center;

	color: #f5f5f5;
	padding: 0.5rem;
}

.class_card_details_wrapper {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-grow: 1;
}

.class_card_subject_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
}

.class_card_subject {
	background: var(--class_card_subject_bg);
	border-radius: 81.5909px;
	color: #0e0d0d;
	padding: 0.25rem 1rem;
	margin-bottom: 0;
}

.class_card_duration {
	background: rgba(198, 198, 198, 0.2);
	border-radius: 8px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	/* line-height: 1px; */
	/* identical to box height, or 100% */

	display: flex;
	align-items: center;

	color: #252525;
	margin-bottom: 0;
	padding: 0.1rem 0.8rem;
}

.class_card_topic_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 22px;
	/* identical to box height */
	color: #454545;
}

.class_card_lecture_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-transform: capitalize;
	color: #747474;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

.class_card_button {
	background: #012c63;
	border: 1px solid #012c63;
	border-radius: 20px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	/* identical to box height */
	text-align: center;

	outline: none;
	height: 32px;

	color: #ffffff;

	padding: 0 1rem;
	min-width: 90px;
}

.class_button_wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 1.3rem;
}

.class_button_join_img {
	margin-top: -0.2rem;
	margin-right: 0.5rem;
}

.class_notes_wrapper {
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
	cursor: pointer;
}

.class_notes_disabled {
	opacity: 0.4;
	cursor: not-allowed;
}

.class_notes_text {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	text-decoration-line: underline;
	color: #2d3368;
	margin-bottom: 0;
}

.class_info_wrapper {
	display: flex;
	justify-content: space-between;
	min-height: 30px;
	align-items: center;
}

.class_card_info_img {
	margin-top: -0.15rem;
}

.class_card_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 17px;
	/* identical to box height */
	color: #7b7b7b;
	margin-bottom: 0;
}

.dashboard_blur {
	position: absolute;
	inset: -1px;
	background: rgba(255, 255, 255, 0.6);
	border-radius: 8px;
	backdrop-filter: blur(7px);
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.dashboard_ncert_container {
	background: #ffffff;
	border-radius: 8px;
	padding-top: 1rem;
	margin-top: 4rem;
	scroll-margin-top: 100px;
}

.dashboard_subject_card {
	border-radius: 16px;
	color: var(--dashboard_subject_card_color);
	background-color: var(--dashboard_subject_card_bg);

	min-width: 300px;
	min-height: 140px;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;

	padding: 1.2rem;

	position: relative;
}

.subject_img {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
}

.test_module_drop {
	width: 150px;
	margin-left: 2rem !important;
}

.test_module_drop .ant-select-selector {
	background: rgba(217, 223, 255, 0.39) !important;
	border-radius: 47px !important;
	outline: none !important;

	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 18px;
	/* identical to box height, or 90% */

	letter-spacing: 0.015em;

	color: #151516;
}

.start_button_dashboard {
	margin-top: 1.5rem;
}

.dpp_attempt_text1 {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 13px;
	/* identical to box height, or 130% */
	color: #1f3061;
	margin-bottom: 0;
	margin-top: 0.5rem;
	width: 80px;
}

.check_later_text1 {
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #000000;
	margin-top: 14px;
}

.check_later_text2 {
	font-weight: 500;
	font-size: 16px;
	line-height: 20px;
	color: #828282;
	margin-top: 10px;
}

.meta_date_tag {
	padding: 4px 6px;
	width: 128px;
	height: 26px;
	font-weight: 300;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	letter-spacing: 0.015em;
	color: #151516;
	background: #ffffff;
	border-radius: 47px;
	margin-left: 24px;
}

.user_heading_container {
	display: flex;
	align-items: center;
	flex-grow: 1;
	margin-bottom: 1rem;
}

.pre_launch_desc {
	font-weight: 400;
	font-size: 16px;
	color: #09090a;
}

@media screen and (max-width: 991px) {
	.dashboard_container {
		padding: 1.5rem;
	}

	.dashboard_nav {
		display: none;
	}

	.dashboard_attendance {
		display: none;
	}

	.dashboard_content {
		width: 100%;
		padding: 0;
	}

	.dashboard_mpp_container {
		padding-top: 0;
		background: transparent;
	}

	.arrow_wrapper {
		display: none;
	}

	.dashboard_content_active {
		display: none;
	}

	.dashboard_mpp_card {
		margin: 0;
		min-width: 350px;
		max-width: 350px;
		height: 100%;
	}

	.dashboard_mpp_wrapper {
		padding: 0;
	}

	.dashboard_content_header {
		margin-left: 0;
		font-size: 15px;
		line-height: 20px;
		font-weight: 500;
	}

	.dashboard_content_view_all {
		font-size: 14px;
		font-weight: 400;
		margin-right: 0;
	}

	.dashboard_text2 {
		font-size: 14px;
		line-height: 20px;
	}

	.faculty_mpp_wrapper {
		font-size: 10px;
	}

	.dashboard_test_container {
		padding-top: 0;
		background: transparent;
	}

	.dashboard_test_wrapper {
		padding: 0;
	}

	.dashboard_test_card {
		margin: 0;
		height: 100%;
	}

	.dashboard_class_container {
		padding-top: 0;
		background: transparent;
	}

	.dashboard_class_wrapper {
		padding: 0;
	}

	.dashboard_class_card {
		margin: 0;
		height: 100%;
	}

	.dashboard_ncert_container {
		padding-top: 0;
		background: transparent;
	}

	.dashboard_user_meta {
		display: block;
		background: #ffffff;
	}

	.dashboard_user_avatar_container {
		display: none;
	}

	.meta_date_tag {
		height: 22px;
		background: rgba(217, 223, 255, 0.39);
		font-size: 12px;
		white-space: nowrap;
		padding: 2px 6px;
	}

	.dashboard_student_meta_container {
		margin: 0;
	}

	.dashboard_text1 {
		font-size: 14px;
		line-height: 17px;
	}

	.user_heading_container {
		justify-content: space-between;
	}

	.dpp_button_container {
		justify-content: flex-start;
		margin-top: 12px;
	}

	.pre_launch_desc {
		font-size: 12px;
		line-height: 18px;
	}
	.dashboard_subject_card {
		min-width: 0px;
		width: 100%;
	}

	.dashboard_view_all_card {
		background: #ffffff;
		min-height: 100%;
		font-family: Montserrat;
		font-size: 14px;
		font-weight: 500;
		line-height: 17px;
		letter-spacing: 0em;
		text-align: left;
		margin: 0;
		min-width: 159px;
	}
	.test_module_drop {
		width: 120px;
		margin-left: 1.8rem !important;
	}

	.test_module_drop .ant-select-selector {
		height: 24px !important;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: 0.015em;
		display: flex;
		align-items: center;
	}

	.check_later_text1 {
		font-size: 16px;
		line-height: 20px;
	}

	.check_later_text2 {
		font-size: 12px;
		line-height: 18px;
		color: #828282;
		margin-top: 6px;
	}
}
