.Detail-con {
  background-color: rgba(196, 196, 196, 0.14);
  width: 100%;
  margin-top: 20px;
  padding: 10px;
}

.detail-con-inner {
  display: flex;
}

.detail-exam-con {
  font-size: 12px;
  display: flex;
  margin: 10px;
  color: #706f6f;
}

.exam-inner-details {
  display: flex;
}

.rem-time {
  background-color: #212e84;
  color: white;
  text-align: center;
  border-radius: 25px;
  font-size: 12px;
  margin-top: 2px;
}

.footer-data {
  background-color: #212e84;
  color: white;
  padding: 15px;
  text-align: center;
}

@media (max-width: 575.98px) {
  svg#image-logo {
    width: 90px;
  }

  .detail-exam-con {
    font-size: 13px;
  }
}

/* Exam Submission styling */
.submission-details-message {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}

.result-btn {
  text-align: center;
  padding: 10px;
  width: 200px;
  margin: 10px;
  font-size: 20px;
  border: 2px solid #e5e5e5;
  background-color: white;
  border-radius: 2px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}
