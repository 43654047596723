.footer {
	display: flex;
	gap: 30px;
	font-weight: 400 !important;
	align-items: center;
	justify-content: space-between;
	background-color: #ecf1fe;
}

.footerMain {
	padding: 1% 4%;
	background-color: #ecf1fe;
}

.mailAnchor,
.mailAnchor:hover {
	display: flex;
	align-items: center;
	gap: 5px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	color: #0e0d0d;
}

.copyright {
	display: flex;
	align-items: center;
	gap: 5px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	line-height: 30px;
	color: #0e0d0d;
}

.googleCopyrightFlex {
	display: flex;
	gap: 30px;
}

.socialsContainer {
	display: flex;
	gap: 10px;
	align-items: center;
}

.linksStyle {
	color: #000000;
	display: flex;
	gap: 5px;
	align-items: center;
	text-align: center;
}

.linksStyle:hover {
	color: #000000;
}

.bulletDivider {
	color: #000000;
	font-size: 2rem;
}

@media (max-width: 991px) {
	.footer {
		flex-direction: column;
		gap: 20px;
		align-items: center;
		font-size: 0.9em !important;
	}

	.footerMain {
		padding: 5px 0px;
	}

	.googleCopyrightFlex {
		display: flex;
		gap: 15px;
	}

	.dashboard_container {
		padding: 0rem 1rem;
	}
}
