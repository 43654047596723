.scrollerWrapper {
	display: flex;
	overflow: auto;
	padding-right: 2rem;
	scroll-behavior: smooth;
	scroll-snap-type: x mandatory;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scrollerWrapper::-webkit-scrollbar {
	display: none;
}

.arrowWrapper {
	padding: 1.2rem 1.5rem;
	display: flex;
	justify-content: flex-end;
}

.leftArrow {
	margin-right: 1.5rem;
	cursor: pointer;
}

.rightArrow {
	cursor: pointer;
}

@media screen and (max-width: 991px) {
	.scrollerWrapper {
		padding: 0;
	}

	.arrowWrapper {
		display: none;
	}
}
