.container {
	padding: 10px 14px;
	border-radius: 30px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	color: #201f1f;
	background: #ffffff;
	white-space: nowrap;
	letter-spacing: 0;
	display: flex;
	align-items: center;
	height: fit-content;
	width: fit-content;
	line-height: 27px;
}

.small {
	font-size: 16px;
}

@media screen and (max-width: 991px) {
	.container {
		padding: 6px 10px;
		font-size: 14px;
	}
	.small {
		font-size: 12px;
	}
}
