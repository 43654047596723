.help_support_container{
    display: flex;
    width: 100%;
    padding-inline: 10% ;
    padding-block: 5% ;
  }
  
  .header_text_help_support{
    font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height */
  color: #000000;
  }
  
  .help_support_text1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .help_support_info_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 70%;
    flex: 1 0 70%;
  }

  .help_support_options_row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .help_support_img_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    padding-top: 100px;
    flex: 0 0 30%;
  }

  .options_button{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background: #FFFFFF;
    border: 0.7px solid rgba(28, 54, 135, 0.3);
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */


    color: #1C3687;
  }

  .options_button_active{
    box-sizing: border-box;
/* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background: #EBF0FF;
    border: 1px solid #1C3687;
    border-radius: 8px;
    margin-right: 10px;
    cursor: pointer;

    flex: none;
    order: 0;
    flex-grow: 0;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #1C3687;
  }

  .text_area_div{
    display: flex;
    width: 100%;
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 5px;
  }

  .add_img_button{
   cursor: pointer;
   margin-inline : 8px;
   margin-block : 7px ;
  }

  .add_img_button_container{
    display: flex;
    flex-direction: column-reverse;
  }

  .img_tag{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 7px 13px 12px;
    gap: 10px;
    height: 50px;
    background: #F9F9F9;
    border-radius: 8px;
    margin-block : 10px ;

    border: none;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.02em;
    color: #505050;
    margin: 10px 20px 10px 0px;

    cursor: pointer;
  }

  .tags_container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-block : 20px;
    width: 100%;
  }

  .submit_button_container{
    display: flex;
    justify-content: flex-end;
    /* width: 100%; */
  }

  .submit_button_active{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    height: 49px;

    border:0px;

    background: #012C63;
    border-radius: 8px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    /* identical to box height, or 105% */

    display: flex;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    
  }

  button.submit_button_active:disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    height: 49px;

    border: 0px;

    background: #b4c0d0;
    border-radius: 8px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;

    display: flex;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    cursor: not-allowed;
  }

  /* .submit_button_inactive{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    gap: 10px;

    height: 49px;

    border: 0px;

    background: #b4c0d0;
    border-radius: 8px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;

    display: flex;
    text-align: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    cursor: not-allowed;
  } */

  .cross_img{
    margin-right:10px ;
    margin-left: 5px;
  }

  .info_col{
    display: flex;
    max-width: 740px;
    flex-direction: column;
  }

  /* height: calc(100vh - 52px - 30px); */

  @media screen and (max-width: 1400px) {
   .help_support_img_container{
    display: none;
   }
   .help_support_info_container{
    width: 100%;
   }
   /* .tags_container{
    width: 100%;
   } */
}