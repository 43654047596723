.unanswered_inst {
  width: 40px;
  height: 40px;
  background: linear-gradient(
    123.24deg,
    #c4c4c4 0%,
    rgba(196, 196, 196, 0.43) 123.1%
  );
  border: 1px solid #adadad;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25),
    inset 3px 4px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Not_answered {
  background-image: url("../../icons/Rectangle 14.png");
  width: 42px;
  height: 42px;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Answered {
  background-image: url("../../icons/Rectangle 15.png");
  width: 42px;
  height: 42px;
  background-repeat: no-repeat;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Review {
  background: radial-gradient(
    84.31% 84.31% at 40.2% 15.69%,
    #520a9a 0%,
    rgba(80, 11, 148, 0.6) 100%
  );
  width: 42px;
  height: 42px;
  border-radius: 50%;
  text-align: center;
  color: white;
  padding: 10px;
  border: 1px solid #520a9a;
  font-family: "Montserrat", sans-serif;
  margin: 0.4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Review_answered {
  font-family: "Montserrat", sans-serif;
  background-image: url("../../icons/Review_Answered_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 42px;
  height: 42px;
  /* border-radius: 50%; */
  text-align: center;
  color: white;
  padding: 10px;
  /* padding-left: 4px; */
  /* font-size: 21px; */
  margin: 0.4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mob-res .Review_answered {
  width: 65;
}

@media (max-width: 575.98px) {
  .mob-res .Review_answered {
    width: auto;
  }
}
