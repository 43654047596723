@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700&display=swap");
* {
  font-family: "Poppins", "sans-serif";
}

#one_question_data_con {
  transition: width 0.5s;
}

#right_question_con {
  transition: display 1s;
}

hr {
  margin-top: 0px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid #eee;
}

.ins_con_sec {
  font-family: "Montserrat", sans-serif;
  display: flex;
  background-color: #c4c4c424;
  justify-content: space-between;
  margin-top: 20px;
  padding: 20px 30px 10px 30px;
  align-items: center;
}

.ins_page_heading {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 15px;
}

#heading_three {
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
}

.main_content_ins_page {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
}
.general_list {
  margin: 10px;
}
.internal_list {
  margin-left: 50px;
}
.internal_li_main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.box_list {
  display: flex;
  align-items: center;
}
.center_img {
  margin: 10px 10px 10px 10px;
}
.pro_btn {
  background-color: #6dbc1f;
  color: white;
  width: 200px;
  border: 0px;
  padding: 10px;
}
.pro_btn2 {
  background-color: #a9afa2;
  color: white;
  width: 200px;
  border: 0px;
  padding: 10px;
}

.page-submit {
  margin-top: 50px;
}

.view-res-btn {
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px 10px 20px;
  border: 1px solid #bcbcbc;
}

.mains-main-container {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  justify-content: space-between;
}

.content {
  flex-grow: 1;
  position: relative;
}

.screen-col {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 218px);
  max-height: calc(100vh - 218px);
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  background: #ffffff;
}

.screen-col#one_question_data_con {
  /* Arrow button width */
  padding-right: 30px;
}

.right-col {
  padding-left: 16px;
  padding-right: 16px;
  position: absolute;
  right: 0px;
  z-index: 1 !important;
}

@media screen and (max-width: 991px) {
  .screen-col {
    height: auto;
    max-height: 100%;
    display: flex !important;
  }
  .mains-main-container {
    display: block;
    max-height: 100vh;
    height: auto;
    overflow: auto;
  }
  .right-col {
    padding-right: 16px;
    position: static;
  }
  .screen-col#one_question_data_con {
    padding-right: 0px;
  }
}
